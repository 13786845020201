import React, { useEffect } from "react"
import SummitBanner from "../../components/productSupportSummit/summit-banner"
import WhyAttend from "../../components/productSupportSummit/why-attend"
import WhatToExpect from "../../components/productSupportSummit/what-to-expect"
import SummitData from "../../components/productSupportSummit/summit-data"
import Agenda from "../../components/productSupportSummit/agenda"
import SummitLocation from "../../components/productSupportSummit/location"
import AcceptInvitation from "../../components/productSupportSummit/accept-invitation"
import About from "../../components/productSupportSummit/about"
import Application from "../../components/productSupportSummit/application"
import SummitModal from "../../components/productSupportSummit/summitModal"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProductSupportSummit = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [formId, setFormId] = React.useState(
    "4f800234-ccf4-4d0c-a034-c0d5eab9d964"
  )
  const open = () => setShowModal(true)
  const close = () => setShowModal(false)

  useEffect(() => {
    const background = document.body.querySelector("#___gatsby")
    if (showModal) {
      document.body.style.overflow = "hidden"
      background["style"].filter = "blur(8px)"
    } else {
      document.body.style.overflow = "scroll"
      background["style"].filter = "none"
    }
  })

  return (
    <>
      <SummitModal showModal={showModal} close={close} id={formId} />
      <Layout bg="white" newsletter={null} headerColor="primary">
        <SEO title="Product Support Summit" />
        <SummitBanner open={open} setFormId={setFormId} />
        <WhyAttend />
        <SummitData />
        <WhatToExpect />
        <Agenda />
        <SummitLocation />
        <AcceptInvitation open={open} setFormId={setFormId} />
        <Application open={open} setFormId={setFormId} />
        <About />
      </Layout>
    </>
  )
}

export default ProductSupportSummit

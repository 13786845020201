import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "../typography"
import colors, { foregroundColors } from "../../styles/colors"
import {
  DesktopMediaQuery,
  CARD_MAX_WIDTH,
  CARD_MAX_WIDTH_HEADER,
  MobileMediaQuery,
  PADDING_HORIZONTAL,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"

const Text = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 0 0 200px 120px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
    color: ${colors.darkgrey};
  }
`

const Title = styled(Typography)`
  ${MobileMediaQuery} {
    color: ${colors.darkgrey};
  }
`

const Description = styled(Typography)`
  max-width: 450px;
  margin-top: 2rem;
  font-weight: 500;
  ${MobileMediaQuery} {
    margin-top: 3rem;
  }
`

const BoldText = styled.div`
  font-weight: 700;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const Image = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  ${DesktopMediaQuery} {
    margin-right: -${PADDING_HORIZONTAL}px;
  }
  ${MobileMediaQuery} {
    justify-content: center;
    background-color: ${colors.white};
    height: calc(100vw - 8px);
  }
`

const GatsbyImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const CardImage = ({ fluid }) => {
  return (
    <GatsbyImg
      fluid={fluid}
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Gathering of people"
    />
  )
}

const CardHeaderWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH_HEADER}px;
  height: 600px;
  background-color: ${colors.white};
  color: ${foregroundColors.white};
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    background-color: transparent;
    height: initial;
    margin-bottom: 60px;
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: ${CARD_MAX_WIDTH}px;
  display: flex;
  height: 100%;
  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const WhatToExpect = () => {
  const fluid = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "productSupportSummit/whatToExpect.jpg" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `).image.childImageSharp.fluid

  return (
    <CardHeaderWrapper>
      <ContentWrapper>
        <Image>
          <CardImage fluid={fluid} />
        </Image>
        <Text>
          <Top>
            <Title variant="h2">
              What should <br />
              you expect?
            </Title>
          </Top>

          <Description variant="bodySmall">
            Product Support Summit's approximately 35 attendees will have a
            fully curated, all-inclusive experience. The two day summit will
            include:
            <br />
            <br />
            <BoldText>Two high-prio topics</BoldText>
            designed for knowledge sharing around "Scaling hardware support" and
            "Thriving in a post-COVID world"
            <br />
            <br />
            <BoldText>Peer-to-peer meetings</BoldText>
            with other support leaders in your industry
            <br />
            <br />
            <BoldText>Expert meetings 1-to-1</BoldText>
            with Mavenoid experts
            <br />
            <br />
            <BoldText>Fully-covered travel</BoldText>
            lodging, meals & entertainment to, from, and during the summit
            <br />
            <br />
            ... plus a few exciting surprises.
          </Description>
        </Text>
      </ContentWrapper>
    </CardHeaderWrapper>
  )
}

export default WhatToExpect

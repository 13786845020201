import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "../typography"
import colors, { foregroundColors } from "../../styles/colors"
import {
  DesktopMediaQuery,
  CARD_MAX_WIDTH,
  MobileMediaQuery,
  PADDING_HORIZONTAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"
import UILink from "../link"

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 0 0 0 ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    margin-top: 0;
    padding: 0 0 0 ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const LocationTitle = styled(Typography)`
  color: ${colors.darkgrey};
`

const Description = styled(Typography)`
  max-width: 600px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: ${colors.darkgrey};
  padding: 0 0 50px 0;
`

const Image = styled.div`
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  ${DesktopMediaQuery} {
    margin-right: -${PADDING_HORIZONTAL}px;
  }
  ${MobileMediaQuery} {
    justify-content: center;
    background-color: ${colors.white};
    height: calc(100vw - 8px);
  }
`

const GatsbyImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const CardImage = ({ fluid }) => {
  return (
    <GatsbyImg
      fluid={fluid}
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Gathering of people"
    />
  )
}

const CardHeaderWrapper = styled.div`
  height: 900px;
  background-color: ${colors.white};
  color: ${foregroundColors.white};
  max-width: ${CARD_MAX_WIDTH}px;
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    background-color: transparent;
    height: initial;
    margin-bottom: 60px;
  }
`

const Link = styled(UILink)`
  color: ${colors.primary};
  cursor: pointer;
`

const SummitLocation = () => {
  const images = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "productSupportSummit/location.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <CardHeaderWrapper>
      <Text>
        <LocationTitle variant="h2">Location</LocationTitle>

        <Description variant="bodySmall">
          The summit is hosted at{" "}
          <Link to="https://hotelcasa.nl/">Hotel Casa</Link> in central
          Amsterdam
        </Description>
      </Text>
      <Image>
        <CardImage fluid={images.image.childImageSharp.fluid} />
      </Image>
    </CardHeaderWrapper>
  )
}

export default SummitLocation

import styled from "@emotion/styled"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"
import colors from "../../styles/colors"
import Typography from "../typography"
import {
  DesktopMediaQuery,
  CARD_MAX_WIDTH,
  CARD_MAX_WIDTH_HEADER,
  MobileMediaQuery,
  PADDING_HORIZONTAL,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"
import AspectRatio from "../aspectRatio"

const TileWrapper = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: column;
  ${MobileMediaQuery} {
    width: 100%;
    margin: 0;
  }
`

const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${MobileMediaQuery} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Tile = styled.div`
  height: 280px;
  margin: 0 16px 0 16px;
  overflow: hidden;
  background-color: ${colors.lightgrey};
  ${MobileMediaQuery} {
    width: 100%;
    margin: 0;
  }
`

const TopTile = styled(Tile)`
  border-radius: 16px 16px 0 0;
`

const BottomTile = styled(Tile)`
  border-radius: 0 0 16px 16px;
  ${MobileMediaQuery} {
    margin-bottom: 16px;
  }
`

const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto 105px;
  max-width: ${CARD_MAX_WIDTH}px;
  ${MobileMediaQuery} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Text = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 35px 60px 40px 40px;
`

const Title = styled(Typography)`
  color: ${colors.darkgrey};
  display: inline-flex;
`

const AgendaTitle = styled(Title)`
  padding: 0 140px 60px 140px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
    max-width: 450px;
  }
`

const Description = styled(Typography)`
  max-width: 450px;
  margin-top: 1rem;
  font-weight: 500;
  color: ${colors.darkgrey};
  ${MobileMediaQuery} {
    margin-top: 2rem;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: ${CARD_MAX_WIDTH}px;
  display: flex;
  height: 100%;
  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const GatsbyImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const CardImage = ({ fluid }) => {
  return (
    <GatsbyImg
      fluid={fluid}
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Agenda image"
    />
  )
}

const Image = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
  overflow: hidden;
  ${DesktopMediaQuery} {
    margin-right: -${PADDING_HORIZONTAL}px;
  }
  ${MobileMediaQuery} {
    justify-content: center;
    background-color: ${colors.lightgrey};
    height: calc(100vw - 8px);
  }
`

const CardHeaderWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH_HEADER}px;
  height: 700px;
  background-color: white;
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    background-color: transparent;
    height: initial;
    margin-bottom: 60px;
  }
`

const Agenda = () => {
  const images = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "productSupportSummit/agenda1.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2: file(relativePath: { eq: "productSupportSummit/agenda2.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image3: file(relativePath: { eq: "productSupportSummit/agenda3.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const tiles = [
    {
      titleText: "Day one, June 7",
      bodyText: "Check-in and welcome reception",
    },
    {
      titleText: "Day two, June 8",
      bodyText: (
        <div>
          9 am — 4 pm
          <br />
          Educational tracks with keynotes, panel discussions, and breakout
          groups
          <br />
          <br />
          6 pm — 9 pm
          <br />
          Dinner and surprise activity
        </div>
      ),
    },
    {
      titleText: "Day three, June 9",
      bodyText: (
        <div>
          9 am — 2 pm
          <br />
          Peer-to-peer networking sessions & meetings
          <br />
          <br />
          3 pm
          <br />
          Closing ceremony
        </div>
      ),
    },
  ]

  return (
    <CardHeaderWrapper>
      <AgendaTitle variant="h2">The agenda at a glance</AgendaTitle>
      <TilesContainer>
        <Tiles>
          {tiles.map((tile, index) => (
            <TileWrapper>
              <TopTile>
                <AspectRatio ratio={270 / 380}>
                  <Image>
                    <CardImage
                      fluid={images[`image${index + 1}`].childImageSharp.fluid}
                    />
                  </Image>
                </AspectRatio>
              </TopTile>
              <BottomTile>
                <AspectRatio ratio={270 / 380}>
                  <ContentWrapper>
                    <Text>
                      <Top>
                        <Title variant="h6">{tile.titleText}</Title>
                      </Top>

                      <Description variant="bodySmall">
                        {tile.bodyText}
                      </Description>
                    </Text>
                  </ContentWrapper>
                </AspectRatio>
              </BottomTile>
            </TileWrapper>
          ))}
        </Tiles>
      </TilesContainer>
    </CardHeaderWrapper>
  )
}

export default Agenda

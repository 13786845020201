import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "../typography"
import colors, { foregroundColors } from "../../styles/colors"
import UILink from "../link"
import {
  DesktopMediaQuery,
  CARD_MAX_WIDTH,
  CARD_MAX_WIDTH_HEADER,
  MobileMediaQuery,
  PADDING_HORIZONTAL,
  PADDING_VERTICAL,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"
import { HoverButton } from "../button"

const Text = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.darkgrey};
  padding: 0 0 ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const Title = styled(Typography)`
  ${MobileMediaQuery} {
  }
`

const Description = styled(Typography)`
  max-width: 450px;
  margin-top: 2rem;
  ${MobileMediaQuery} {
    margin-top: 30px;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const Image = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  background-color: ${colors.primary};
  ${DesktopMediaQuery} {
    margin-right: -${PADDING_HORIZONTAL}px;
  }
  ${MobileMediaQuery} {
    justify-content: center;
    height: calc(100vw - 8px);
  }
`

const GatsbyImg = styled(Img)`
  width: 100%;
  height: 100%;
  ${MobileMediaQuery} {
  }
`

const CardImage = ({ fluid }) => {
  return (
    <GatsbyImg
      fluid={fluid}
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Mavenoid Troubleshooter in front of a scooter"
    />
  )
}

const CardHeaderWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH_HEADER}px;
  height: 600px;
  background-color: ${colors.white};
  color: ${foregroundColors.white};
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    background-color: transparent;
    height: initial;
    margin-bottom: 100px;
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: ${CARD_MAX_WIDTH}px;
  display: flex;
  height: 100%;
  ${MobileMediaQuery} {
    flex-direction: column-reverse;
  }
`

const AboutButton = styled(HoverButton)`
  display: flex;
  border-radius: 1000px;
  background-color: transparent;
  color: ${colors.darkgrey};
  border-color: ${colors.darkgrey};
  text-transform: none;
  max-width: 250px;
  align-self: top;
  margin-top: 3rem;
  display: inline-flex;
  justify-content: center;
`

const Link = styled(UILink)`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`

const About = () => {
  const fluid = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "tools/live-support-drone.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `).image.childImageSharp.fluid

  return (
    <CardHeaderWrapper>
      <ContentWrapper>
        <Text>
          <Top>
            <Title variant="h2">About Mavenoid</Title>
          </Top>

          <Description variant="bodySmall">
            Here at Mavenoid, we believe that customer service is the service.
            Customers now demand support on their own terms. They want expert
            help, around the clock, via any channel, without having to repeat
            themselves.
            <br />
            <br />
            That's why we created Mavenoid. A purpose-built platform for
            supporting users of physical products, machines, devices and
            gadgets. We're helping companies automate technical support through
            virtual experts that actually work.
            <br />
            <br />
            Our platform helps some of the biggest hardware companies in the
            world automate and scale product support.
          </Description>
          <AboutButton as={Link} to="/why-mavenoid/">
            Learn more about us
          </AboutButton>
        </Text>
        <Image>
          <CardImage fluid={fluid} />
        </Image>
      </ContentWrapper>
    </CardHeaderWrapper>
  )
}

export default About

import React from "react"
import styled from "@emotion/styled"
import Typography from "../typography"
import colors, { foregroundColors } from "../../styles/colors"
import {
  DesktopMediaQuery,
  CARD_MAX_WIDTH,
  CARD_MAX_WIDTH_HEADER,
  MobileMediaQuery,
  PADDING_HORIZONTAL,
  PADDING_VERTICAL,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"
import { HoverButton } from "../button"
import InputUI from "../input"

const Text = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.white};
  padding: 150px 0 ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const Title = styled(Typography)`
  ${MobileMediaQuery} {
  }
`

const Description = styled(Typography)`
  max-width: 450px;
  margin-top: 2rem;
  ${MobileMediaQuery} {
    margin-top: 3rem;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const CardHeaderWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH_HEADER}px;
  height: 700px;
  background-color: ${colors.primary};
  color: ${foregroundColors.primary};
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    height: initial;
    margin-bottom: 60px;
    overflow: hidden;
  }
  ${DesktopMediaQuery} {
    border-radius: 0;
    max-width: initial;
    margin-left: -4px;
    margin-right: -4px;
  }
`

const ContentWrapper = styled.a`
  margin: 0 auto;
  max-width: ${CARD_MAX_WIDTH}px;
  display: flex;
  height: 100%;
  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const SubmitButton = styled(HoverButton)`
  display: flex;
  border-radius: 1000px;
  background-color: ${colors.white};
  color: ${colors.primary};
  border-color: ${colors.primary};
  text-transform: none;
  min-width: 100px;
  max-width: 100px;
  align-self: top;
  margin-top: 1rem;
  display: inline-flex;
  justify-content: center;
`

const CodeInput = styled(InputUI)`
  max-width: 450px;
  margin-top: 2rem;
`

const AcceptInvitation = ({ setFormId, open }) => {
  const [code, setCode] = React.useState("")
  const [status, setStatus] = React.useState("failure")
  const acceptedCode = "PSS2022"

  //TODO: onclick
  return (
    <CardHeaderWrapper>
      <ContentWrapper id="accept-invitation">
        <Text>
          <Top>
            <Title variant="hero">Accept your invitation</Title>
          </Top>

          <Description variant="bodySmall">
            Enter your code to accept your invitation to the Product Support
            Summit 2022 in Amsterdam.
            <br />
            <br />
            Upon receipt, a member of the Mavenoid team will be in touch to
            share more details and to coordinate your itinerary and personal
            agenda.
          </Description>
          <br />
          <CodeInput
            id="invitation-code"
            type="text"
            onChange={e => {
              setCode(e.target.value)
              setStatus(e.target.value === acceptedCode ? "success" : "failure")
            }}
            value={code}
            required
            placeholder="Invitation code"
            pattern="PSS2022"
            title="valid invitation code"
          />
          <SubmitButton
            type="submit"
            onClick={e => {
              if (status === "success") {
                e.preventDefault()
                setFormId("4f800234-ccf4-4d0c-a034-c0d5eab9d964")
                open()
              }
            }}
          >
            Submit
          </SubmitButton>
        </Text>
      </ContentWrapper>
    </CardHeaderWrapper>
  )
}

export default AcceptInvitation

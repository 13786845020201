import styled from "@emotion/styled"
import React from "react"
import colors from "../../styles/colors"
import Typography from "../typography"
import {
  CARD_MAX_WIDTH,
  CARD_MAX_WIDTH_HEADER,
  MobileMediaQuery,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"
import AspectRatio from "../aspectRatio"

const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  ${MobileMediaQuery} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Tile = styled.div<{ backgroundColor: string }>`
  width: 31.33%;
  height: 330px;
  margin: 1%;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${p => p.backgroundColor};
  ${MobileMediaQuery} {
    width: 100%;
    margin: 0;
    margin-bottom: 16px;
  }
`

const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto 105px;
  max-width: ${CARD_MAX_WIDTH}px;
  ${MobileMediaQuery} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Text = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 50px 80px 50px 80px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const Title = styled(Typography)<{ titleTextColor: string }>`
  color: ${p => colors[p.titleTextColor]};
  display: inline-flex;
  justify-content: center;
  ${MobileMediaQuery} {
    font-size: 60px;
    line-height: 64px;
    margin-top: 10px;
    max-width: 200px;
  }
`

const Description = styled(Typography)<{ bodyTextColor: string }>`
  max-width: 450px;
  margin-top: 2rem;
  font-weight: 500;
  height: 100%;
  color: ${p => colors[p.bodyTextColor]};
  ${MobileMediaQuery} {
    margin-top: 40px;
    max-width: 300px;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH}px;
  display: flex;
  height: 100%;
  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const CardHeaderWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH_HEADER}px;
  height: 380px;
  background-color: white;
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    background-color: transparent;
    height: initial;
    margin-bottom: 60px;
  }
`

//FIX MOBILE TEXT

const SummitData = () => {
  const tiles = [
    {
      textColor: "graphite",
      titleText: "2.5 days",
      bodyColor: "lightgray",
      bodyText: "of networking and knowledge sharing",
    },
    {
      textColor: "white",
      titleText: "10+ sessions",
      bodyColor: `${colors.darkgrey}`,
      bodyText: "on topics including AI, automation, and remote support",
    },
    {
      textColor: "graphite",
      titleText: "35 leaders",
      bodyColor: "lightgray",
      bodyText: "from the world's top hardware brands in an exclusive event",
    },
  ]

  return (
    <CardHeaderWrapper>
      <TilesContainer>
        <Tiles>
          {tiles.map(tile => (
            <Tile backgroundColor={tile.bodyColor}>
              <AspectRatio ratio={270 / 380}>
                <ContentWrapper>
                  <Text>
                    <Top>
                      <Title titleTextColor={tile.textColor} variant="h2">
                        {tile.titleText}
                      </Title>
                    </Top>

                    <Description
                      bodyTextColor={tile.textColor}
                      variant="bodyLarge"
                    >
                      {tile.bodyText}
                    </Description>
                  </Text>
                </ContentWrapper>
              </AspectRatio>
            </Tile>
          ))}
        </Tiles>
      </TilesContainer>
    </CardHeaderWrapper>
  )
}

export default SummitData

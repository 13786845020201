import React, { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import styled from "@emotion/styled"
import ReactModal from "react-modal"
import colors from "../../styles/colors"
import { MobileJSMediaQuery } from "../../styles/constants"
import Cross from "../icons/cross"
import Typography from "../typography"
import {
  DesktopMediaQuery,
  MobileMediaQuery,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #666666;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 60px 0 60px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
    color: ${colors.darkgrey};
  }
`

const Title = styled(Typography)`
  text-align: center;
  color: ${colors.primary};
`

const Description = styled(Typography)`
  text-align: center;
  ${DesktopMediaQuery} {
    max-width: 600px;
  }
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${colors.darkgrey};
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled(Typography)`
  height: 600px;
  #hubspotform {
    height: 100%;
  }
  form {
    height: 100%;
  }
  input[type="submit"] {
    border-radius: 45px;
    width: 225px;
    height: 77.5px;
    background-color: ${colors.primary};
    color: ${colors.white};
    border-style: solid;
    text-transform: none;
    padding-left: 0;
  }
  input {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 4px;
    background: ${colors.white};
    height: 37px;
    width: 100% !important;
    padding-left: 13px;
  }
  input:invalid {
    border-color: ${colors.error};
  }
  input:empty {
    border-color: ${colors.grey};
  }
  #hubspotform {
    width: 250px;
    margin: 0 auto;
  }
  fieldset {
    width: 100%;
    min-width: 100%;
  }
  select {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: ${colors.grey};
    background: ${colors.white};
    height: 37px;
  }
  .fieldset.form-columns-2 .hs-form-field {
    width: 121px;
  }
  .fieldset.form-columns-1 .hs-input {
    width: 100%;
  }
  .hs-firstname {
    margin-right: 8px;
  }
  .form-columns-1 {
    margin-bottom: 8px;
  }
  .form-columns-2 {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }
  .hs-submit {
    position: absolute;
    bottom: 40px;
    margin-left: 12.5px;
    height: 77.5px;
    width: 225px;
  }
  .input {
    padding-right: 0;
    margin-right: 0 !important;
  }
  .hs-input {
    width: 100% !important;
  }
  .legal-consent-container {
    text-align: center;
  }
  .hs-error-msgs {
    margin-top: 4px;
    margin-bottom: 0;
    padding-left: 0;
  }
`

const HubspotContactForm = props => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      window["hbspt"].forms.create({
        region: "na1",
        portalId: "21167530",
        formId: props?.id || "4f800234-ccf4-4d0c-a034-c0d5eab9d964",
        target: "#hubspotform",
      })
    })
  }, [props.id])

  return (
    <FormWrapper variant="small">
      <div id="hubspotform"></div>
    </FormWrapper>
  )
}

const SummitModal = props => {
  const isMobile = useMediaQuery({ query: `${MobileJSMediaQuery}` })
  const modalStyle = {
    modal: {
      width: isMobile ? "100%" : "450px",
    },
    content: {
      height: isMobile ? "100%" : "95%",
      marginLeft: isMobile ? "0%" : "auto",
      marginRight: isMobile ? "0%" : "auto",
      width: isMobile ? "100%" : "450px",
      borderRadius: "16px",
      inset: isMobile ? "0" : "2.5% 0 0 0",
      overflow: "hidden",
    },
    overlay: {
      zIndex: "1000",
      backgroundColor: "transparent",
    },
  }
  ReactModal.setAppElement("#___gatsby")
  const isInvited = props?.id === "4f800234-ccf4-4d0c-a034-c0d5eab9d964"
  const form = <HubspotContactForm id={props?.id} />
  return (
    <ReactModal
      isOpen={props?.showModal}
      onRequestClose={props?.close}
      style={modalStyle}
      shouldCloseOnOverlayClick={false}
    >
      <CloseButton aria-label="close popup" onClick={props?.close}>
        <Cross style={{ transform: "rotate(45deg)", color: colors.white }} />
      </CloseButton>
      <Text>
        <Top>
          <Title variant="h6">
            {isInvited
              ? "Welcome to the Product Support Summit 2022"
              : "Apply to attend the Product Support Summit 2022"}
          </Title>
          <Description variant="small">
            {isInvited
              ? "Enter your information to accept your invitation. A member of of team will then reach out to coordinate your itinerary."
              : "Enter your information and a member of the Mavenoid team will review your application."}
          </Description>
        </Top>
        {form}
      </Text>
    </ReactModal>
  )
}

export default SummitModal

import React from "react"
import styled from "@emotion/styled"
import Typography from "../typography"
import colors, { foregroundColors } from "../../styles/colors"
import {
  CARD_MAX_WIDTH,
  CARD_MAX_WIDTH_HEADER,
  MobileMediaQuery,
  PADDING_HORIZONTAL,
  PADDING_VERTICAL,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"
import { HoverButton } from "../button"

const Text = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${colors.darkgrey};
  padding: 0 0 ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const Title = styled(Typography)`
  max-width: 450px;
  ${MobileMediaQuery} {
  }
`

const Description = styled(Typography)`
  max-width: 450px;
  margin-top: 2rem;
  ${MobileMediaQuery} {
    margin-top: 3rem;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const CardHeaderWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH_HEADER}px;
  height: 500px;
  background-color: ${colors.white};
  color: ${p => foregroundColors.white};
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    background-color: transparent;
    height: initial;
    margin-bottom: 60px;
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: ${CARD_MAX_WIDTH}px;
  display: flex;
  height: 100%;
  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const ApplicationButton = styled(HoverButton)`
  display: flex;
  border-radius: 1000px;
  background-color: transparent;
  color: ${colors.darkgrey};
  border-color: ${colors.darkgrey};
  text-transform: none;
  max-width: 250px;
  align-self: top;
  margin-top: 3rem;
  display: inline-flex;
  justify-content: center;
`

const Application = ({ setFormId, open }) => {
  return (
    <CardHeaderWrapper>
      <ContentWrapper>
        <Text>
          <Top>
            <Title variant="h2">
              Interested in attending but haven't received an invitation?
            </Title>
          </Top>

          <Description variant="bodySmall">
            Enter your information and a member of the Mavenoid team will review
            your application.
          </Description>
          <ApplicationButton
            onClick={() => {
              setFormId("801184f2-b9e2-46b0-b8ee-005935f8041e")
              open()
            }}
          >
            Apply to attend
          </ApplicationButton>
        </Text>
      </ContentWrapper>
    </CardHeaderWrapper>
  )
}

export default Application

import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"
import Typography from "../typography"
import colors, { foregroundColors } from "../../styles/colors"
import {
  DesktopMediaQuery,
  CARD_MAX_WIDTH,
  CARD_MAX_WIDTH_HEADER,
  MobileMediaQuery,
  PADDING_HORIZONTAL,
  PADDING_VERTICAL,
  MOBILE_PADDING_VERTICAL,
  MOBILE_PADDING_HORIZONTAL,
} from "../../styles/constants"

const Text = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 0 0 ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
    color: ${colors.darkgrey};
  }
`

const Title = styled(Typography)`
  ${MobileMediaQuery} {
    color: ${p => colors.darkgrey};
  }
`

const Description = styled(Typography)`
  max-width: 450px;
  margin-top: 2rem;
  font-weight: 500;
  ${MobileMediaQuery} {
    margin-top: 3rem;
    max-width: 100%;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const Image = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  ${DesktopMediaQuery} {
    margin-right: -${PADDING_HORIZONTAL}px;
  }
  ${MobileMediaQuery} {
    justify-content: center;
    background-color: ${colors.white};
    height: calc(100vw - 8px);
  }
`

const GatsbyImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const CardImage = ({ fluid }) => {
  return (
    <GatsbyImg
      fluid={fluid}
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Mavenoid Troubleshooter in front of a scooter"
    />
  )
}

const CardHeaderWrapper = styled.div`
  max-width: ${CARD_MAX_WIDTH_HEADER}px;
  height: 600px;
  background-color: ${colors.white};
  color: ${foregroundColors.white};
  margin: 0 auto 105px;
  overflow: hidden;
  ${MobileMediaQuery} {
    background-color: transparent;
    height: initial;
    margin-top: 60px;
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: ${CARD_MAX_WIDTH}px;
  display: flex;
  height: 100%;
  ${MobileMediaQuery} {
    flex-direction: column-reverse;
  }
`

const WhyAttend = () => {
  const fluid = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "productSupportSummit/whyAttend.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `).image.childImageSharp.fluid

  return (
    <CardHeaderWrapper>
      <ContentWrapper>
        <Text>
          <Top>
            <Title variant="h2">
              What is the Product Support Summit and why should I attend?
            </Title>
          </Top>

          <Description variant="bodySmall">
            Support leaders at hardware and electronics companies face unique
            challenges, yet no dedicated space exists to come together and share
            ideas. Product Support Summit 2022 is the first community event
            curated specifically for leaders in technical product support.
            <br />
            <br />
            Expect to walk away from the summit with tangible learnings that you
            can apply to your support organization, deep connections with
            industry peers, and insights into the advancements across automation
            and AI in the world of product support.
          </Description>
        </Text>
        <Image>
          <CardImage fluid={fluid} />
        </Image>
      </ContentWrapper>
    </CardHeaderWrapper>
  )
}

export default WhyAttend
